import { graphql, useStaticQuery } from 'gatsby';

/**
 * gatsby-config에서 정의한 siteMetadata를 접근해서 사용하기 위한 custom hook
 * @returns
 */
export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          appLink
          appLinkApple
          appLinkGoogle
          apiUrl
          title
          description
          descriptionMagazines
          descriptionEvents
          descriptionSearch
          amplitudeKey
          kakaoApiKey
          keywords
          copyRight
          siteUrl
          stage
          sharedImg
          appPckgName
        }
      }
    }
  `);
  return data.site.siteMetadata;
};
